*,
::after,
::before {
    box-sizing: border-box;
}

.all-apps-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20%;
    margin-right:20%;
    margin-bottom: 200px;

}

.section-title-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}


.trending-apps-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section-title-wrapper h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5em;
    position: relative;
    padding-left: 15px;
    justify-content: center;


}

.section-title-wrapper h3:before {
    content: '';
    left: 0;
    width: 7px;
    height: 30px;
    border-radius: 10px;
    display: block;
    position: absolute;
    background-color: #2f65ff;
}


.app-card {
    background-color: #2a3042;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top: 5px;
    padding: 10px;
    width: 100%;
}

.app-card-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app-image {
    width: 80px;
    max-width: 100%;
    border-radius: 12px;
    margin-right: 10px;
}

.app-author {
    font-size: 10px;
}

.app-name {
    font-weight: bold;
    font-size: 1.4em;
    line-height: 20px;
}

.app-content {
    display: flex;
    height: 2em;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

}

.app-rating {
    font-size: 24px;
    color: #FFB800;
    margin-right: 5px;
}

.app-rating-count {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #a6b0cf;
}

.app-ubication {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.app-description {
    font-weight: 400;
    font-size: 0.8em;
    line-height: 1.4;
    color: #a6b0cf; 
    margin-top: 5px;
}

.app-platforms {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.app-platform-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
.app-icon {
    color: #a6b0cf;
    font-size: 24px;
}

.all-apps-content {
        display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            margin-top: 20px;
            max-width: 1080px;
            margin-left: auto;
            margin-right: auto;

}

.qsearch-input {
    display: flex;
    align-items: center;
    background-color: #2a3042;
    border-radius: 400px;
    padding: 12px 12px;
    width: 400px;
    border: 1px solid #2a3042;
}

.qsearch-input input {
    width: 100%;
    background-color: transparent;
    border: none;
    margin-left: 8px;
    font-size: 16px;
    color: #a6b0cf;
}


.qsearch-icon {
    fill: #2f65ff;
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 1200px) {
    .all-apps-content {
        margin-left: 14px; 
        margin-right: 14px;
    }
    .section-title-wrapper {
    width: 40%;
    }

                        .all-apps-title-container {
                            margin-left: auto;
                            margin-right: auto;
            
                        }
}

@media screen and (max-width: 900px) {
    .all-apps-content {
        grid-template-columns: 1fr;
    }
    .section-title-wrapper {
        width: 100%;
    }
                        .all-apps-title-container {
                            margin-left: auto;
                            margin-right: auto;
            
                        }
}

@media screen and (max-width: 600px) {
    .atrending-apps-content {
        grid-template-columns: 1fr;
    }
    .section-title-wrapper {
     width: 90%;
    }
    .app-name {
    font-size: 1.2em;
    }        .all-apps-title-container {
                margin-left: auto;
                margin-right: auto;
    
            }
    

.app-image {
    width: 53px;
}
.app-author {
    font-size: 10px;
}
.app-name {
    font-size: 1.2em;
}

.app-content {
    height: 0.8em;


}

.app-rating {
    font-size: px;
    color: #FFB800;
    margin-right: 5px;
}

.app-rating-count {
    font-weight: 700;
    font-size: 0.85em;
    line-height: 20px;
    color: #a6b0cf;
}

.section-title-wrapper h3:before {

    height: 56px;

}

}

/* Para compatibilidad con diferentes navegadores */
input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a6b0cf;
}
