* {
  margin: 0;
  padding: 0;
  color: white;
}

body {
  background-color: #222736;
  color: #ffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.sectionButton { 
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 700px) {
  .sectionButton { 
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  
}