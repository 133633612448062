.totally {
  z-index: 2;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  animation: fade-in 2s fade-in-out;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2a3042;
  padding: 1.5em;
  max-width: 440px;
  width: 70%;
  border-radius: 20px;
  animation: slide-up 1s ease-in-out;
}

.button-contener {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: -10px;
  margin-right: -15px;
}

.Button-exit {
  color: #485475;
  font-size: 30px;
}

.component {
    width: 90%;
    text-align: center;
    margin-bottom: 20px;
}

.box-data {
  display: flex;
  flex-direction: row ;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.contener {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid rgba(0, 0, 0, 0.15);
  padding-left: 10px;
  margin-left: 10px;
  color: #a6b0cf;
}

.contener p {
    color: #a6b0cf;
    font-size: 20px;
    margin-left: 4px;
    font-weight: bold;
}
.author-modal {
  font-size: 12px;
  color: #2f65ff;
  margin-bottom: 10px;
}

.app-icons {
  font-size: 24px;
}

.information-modal {
  display: flex;
  flex-direction: column;
  align-items: center;    
  margin-bottom: 20px;    
  text-align: center;       
}

.text-title-c {
    font-size: 28px;
      font-weight: bold;
      margin-bottom: 10px;
}

.text-c {
  color: #a6b0cf;
      font-size: 18px;
      margin-left: 4px;
}

.Footer {
  border-top: 2px solid rgba(0, 0, 0, 0.15);
  padding-top: 20px;
  width: 90%;
  text-align: center;
  margin-bottom: 20px;
}

.text-title {
  color: #25ff22;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.text-Footer {
    font-size: 1.1em;
    font-weight: 400;

}

.Button-star {
  background-color: #2f65ff;
  border-radius: 10px;
  padding: 15px 0px 15px 0px;
  border-width: 0px;
  width: 60%;
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 15px;
  animation: pulse 1s infinite;
}

@media screen and (max-width: 1200px) {
  .modal-content {
    width: 90%;
    /* Reducir el ancho máximo para dispositivos móviles */
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
    /* Agrega esta línea */
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    /* Agrega esta línea */
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    /* Estado inicial: completamente transparente */
  }

  to {
    opacity: 1;
    /* Estado final: completamente visible */
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    /* Estado inicial: tamaño normal */
  }

  50% {
    transform: scale(1.04);
    /* Escala al 110% */
  }

  100% {
    transform: scale(1);
    /* Vuelve al tamaño normal */
  }
}

/* Aplica la animación a tu elemento con clase .modal */
.modal {
  /* Estilos iniciales */
  animation: fade-in 1s ease;
  /* Aplica la animación de fade-in */
}