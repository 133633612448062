.trending-apps {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2a3042;
    padding: 50px 0 30px 0;
    position: relative;
    width: 100%;
}

.trending-apps-title-container {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.trending-apps-content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.trending-apps-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contenedor{
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

.ssection-title-wrapper h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5em;
    position: relative;
    padding-left: 15px;
}

.ssection-title-wrapper h3:before {
    content: '';
    height: 100%;
    left: 0;
    width: 7px;
    border-radius: 10px;
    display: block;
    position: absolute;
    top: 0;
    background-color: #2f65ff;
}

.trending-apps-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 32px;
}

.card {
    background-color: #222736;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    margin: 0 12px 16px 12px;
    /* Agregar margen entre las tarjetas */
    width: 90%;
}

.card-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    /* Centrar el contenido verticalmente */
}

.app-image {
    width: 112px;
    height: auto;
    border-radius: 12px;
}
.content {
    display: flex;
    justify-content: center;
}


.apps-name {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 8px;
    margin-top: 8px;
}

.rating {
    color: gold;
    font-size: 16px;
    margin-bottom: 4px;
}

.ratingCount {
    margin-left: 5px;
    font-weight: 600;
}

.increased-rating {
    color: #ccc;
    font-size: 16px;
    margin-bottom: 4px;
}

.language {
    font-size: 16px;
    margin-top: 4px;
}

@media screen and (max-width: 1200px) {
.trending-apps-title-container {

        width: 98%;
    }
        .ssection-title-wrapper h3 {
            margin-left: 4%;
        }
}

@media screen and (max-width: 900px) {
.ssection-title-wrapper h3:before {
    content: '';
    height: 100%;
    left: 0;
    width: 7px;
    border-radius: 10px;
    display: block;
    position: absolute;
    top: 0;
    background-color: #2f65ff;
}
}

@media screen and (max-width: 600px) {


}