.totally {
  z-index: 2;
}

.modales {
    z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  animation: fade-in 0.5s;

}

.modal-content2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2a3042;
  padding: 1.5em;
  max-width: 340px;
  width: 30%;
  border-radius: 20px;
  animation: slide-up 0.5s ease-in-out;
}

.load-spinner {
  font-size: 7em;
  animation: spin 2s infinite linear;
}

.title-load {
  color: #a6b0cf;
  font-weight: 600;
  letter-spacing: 1.4px;
}

.title-processing {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  animation: bounceIn 0.4s;
}


.progress-bar {
  width: 100%;
  height: 12px;
  background-color: #222736;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 40px;
}

.progress {
  height: 100%;
  background-color: #2f65ff;
  transition: width 1s ease-in-out;
  border-radius: 40px;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }

  60% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 1200px) {
  .modal-content {
    width: 80%;
    /* Reducir el ancho máximo para dispositivos móviles */
  }
}

@media screen and (max-width: 1200px) {
  .modal-content2 {
    width: 90%;
    /* Reducir el ancho máximo para dispositivos móviles */
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
    /* Agrega esta línea */
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    /* Agrega esta línea */
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    /* Estado inicial: completamente transparente */
  }

  to {
    opacity: 1;
    /* Estado final: completamente visible */
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    /* Estado inicial: tamaño normal */
  }

  50% {
    transform: scale(1.04);
    /* Escala al 110% */
  }

  100% {
    transform: scale(1);
    /* Vuelve al tamaño normal */
  }
}

/* Aplica la animación a tu elemento con clase .modal */
.modal {
  /* Estilos iniciales */
  animation: fade-in 1s ease;
  /* Aplica la animación de fade-in */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}