
  *,
    ::after,
    ::before {
    box-sizing: border-box;
    }
.titleTop {
        position: relative;
            text-align: center;
            padding-top: 30px;
            padding-bottom: 30px;
            background-color: #2f65ff;
            background-image: url('./fByq2tk.png');
            background-size: cover
}
.icon {
    padding-left: 20px;
    padding-top: 20px;
    background: none;
    width: 50px;
    margin-bottom: -4vh;
}   
.imageHeader {
    max-width: 120px;
    max-height: none;
}
.elementsTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}
.header-title {
    font-size: 4em;
    font-weight: 900;
    margin: 10px 0;
    color: #fff;
}

.header-subtitle {
    margin: 10px;
    font-size: 1.2em;
    color: #fff;
    font-weight: 400;
    
}
